var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-view" }, [
    _c("p", { staticClass: "view-text" }, [_vm._v("View:")]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.activeViewClass,
        on: {
          click: function ($event) {
            return _vm.$emit("toggleProductView")
          },
        },
      },
      [_vm._m(0), _vm._v(" "), _vm._m(1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select-block-view" }, [
      _c("i", { staticClass: "icon-block-view" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select-row-view" }, [
      _c("i", { staticClass: "icon-row-view" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }