var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "product-listing", attrs: { "data-equalizer-watch": "" } },
    [
      _c(
        "div",
        {
          staticClass: "product-listing-full",
          style: {
            backgroundImage: _vm.collection.productImage
              ? `url('${_vm.collection.productImage}')`
              : null,
          },
          attrs: { "data-equalizer-watch": "" },
        },
        [
          _c("div", { staticClass: "top-section" }, [
            _c("a", {
              staticClass: "floatLink",
              attrs: { href: _vm.collection.url },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "heading" }, [
              _vm.collection.logo
                ? _c("img", { attrs: { src: _vm.collection.logo, alt: "" } })
                : _vm._e(),
              _vm._v(" "),
              _c("hgroup", [
                _c("h3", [_vm._v(_vm._s(_vm.collection.title))]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.collection.productSubheading))]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bottom-section" }, [
            _c(
              "ul",
              { staticClass: "color-option" },
              _vm._l(_vm.collection.colorOptions, function (option) {
                return _c("li", { key: option.id, staticClass: "wrap" }, [
                  _c("a", {
                    staticClass: "color",
                    style: {
                      backgroundImage: `url('${option.colorOptionPhoto}')`,
                    },
                    attrs: {
                      "data-colorpallete": "",
                      "data-prodtooltip": "",
                      "data-image": option.colorOptionPhoto,
                      role: "button",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "colorTip" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(option.title)),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }