var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "color-points" },
    _vm._l(_vm.colors, function (color, index) {
      return _c("label", { key: color.id + 273, attrs: { for: color.id } }, [
        _c("li", {
          style: {
            background: `linear-gradient(to right, ${_vm.gradient(
              color,
              _vm.colors[index + 1]
            )})`,
          },
        }),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }