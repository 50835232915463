<template>
    <div class="products-container">
        <div class="row">
            <ViewToggle :blockViewActive="blockViewActive" v-on:toggleProductView="toggleProductView" />
        </div>
        <div class="row">
            <ProductFilters
                :filters="filters"
                :metaFilters.sync="metaFilters"
                :popoutOpen="popoutOpen"
                v-on:togglePopout="togglePopout"
                v-on:metaFiltersChanged="handleChangeMetaFilters"
                />

            <div class="product-listing-container small-12 large-8 columns">
                <!-- ProductRows -->
                <ProductRows v-if="!blockViewActive" :productCollections="productCollections" :isLoading="isLoading" :error="error" />
                <!-- ProductBlocks -->
                <ProductBlocks
                    v-if="blockViewActive"
                    :productColorOptions="productColorOptions"
                    :isLoading="isLoading"
                    :error="error"
                    :samplesInCart="samplesInCart"
                    v-on:add-to-cart="addSampleToCart" />
                <!-- LoadMoreButton -->
                <div class="small-12 columns load-more-container">
                    <LoadMoreButton v-if="pagination.total_pages > pagination.current_page" v-on:loadMore="handleLoadMore" :isLoadingMore="isLoadingMore" :isLoading="isLoading" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import hydration from '../../js/components/productFilters/hydration'
import debounce from 'lodash/debounce'
import findIndex from 'lodash/findIndex'
import productAPI from '../../js/components/productFilters/api'
import shortid from 'shortid'
import { Cancel } from 'axios'
import { updateCartQuantity, getCartQuantity } from '../../js/components/globalProductConfig/cartButtonEvents'

import ProductRows from './ProductRows'
import ProductBlocks from './ProductBlocks'
import ProductFilters from './ProductFilters'
import ViewToggle from './ViewToggle'
import LoadMoreButton from '../NydreeUI/LoadMoreButton'

const filteringData = window.filterData

const productColors = hydration.hydrateProductColors(filteringData.productColors)
const productWidths = hydration.hydrateProductWidths(filteringData.productWidths)
const productSpecies = hydration.hydrateProductSpecies(filteringData.productSpecies)
const productCollectionCategories = hydration.hydrateProductCollectionCategories(filteringData.productCollections)
const productPrices = hydration.hydrateProductPrices(filteringData.productPrices)

export default {
    components: {
        ProductRows,
        ProductBlocks,
        ProductFilters,
        ViewToggle,
        LoadMoreButton
    },
    data() {
        return {
            isLoading: true,
            isLoadingMore: false,
            isAddingSamples: false,
            blockViewActive: true,
            popoutOpen: false,
            error: {
                hasError: false,
                message: ''
            },
            pagination: {
                total: 12,
                count: 12,
                per_page: 12,
                current_page: 1,
                total_pages: 1,
                links: {}
            },
            filters: {
                productColors,
                productWidths,
                productSpecies,
                productCollectionCategories,
                productPrices
            },
            metaFilters: {
                colorFilters: [],
                speciesOptions: [],
                collectionOptions: [],
                priceOptions: [],
                widthOptions: []
            },
            productColorOptions: [],
            samplesInCart: window.samplesInCart
        }
    },
    created() {
        // Get the initial set of products.
        this.getColorOptions()
        // Then initialize the debouncing of filtering.
        this.debouncedgetColorOptions = debounce(this.getColorOptions, 200)
        // Custom url query filter
        this.urlQueryFilter()
    },
    methods: {
        urlQueryFilter() {
            // Collection filter
            const urlQueryCollection = this.$route.query.collection;
            if(urlQueryCollection) {
                // console.log('collection: ', this.$route.query.collection);
                // console.log('filters', this.filters.productCollectionCategories)
                const res = this.filters.productCollectionCategories.filter((collection) => collection.slug === urlQueryCollection || collection.title === urlQueryCollection)
                if(res.length === 1) {
                    // Clear array first
                    this.metaFilters.collectionOptions = []
                    // Push all children
                    res[0].children.forEach((collectionChild) => {
                        // console.log(collectionChild.id)
                        this.metaFilters.collectionOptions.push(parseInt(collectionChild.id));
                    });
                }
            }

            // Block layout query filter
            const urlQueryblockLayout = this.$route.query.blockLayout;
            if(urlQueryblockLayout === '2') {
                // console.log('urlQueryblockLayout', urlQueryblockLayout)
                // Set to false
                this.blockViewActive = false;
            }
        },
        /**
         * Simply gets the current filters, ready for a URL param
         * @returns {Object} An onject ready to be sent to the API as URL params.
         */
        getCurrentFilterData() {
            // GET THE FILTERS
            // CONCATENATE THEM
            const params = {
                metaFilters: this.metaFilters
            }
            return params
        },
        /**
         * Get color options using the current selected filters.
         */
        getColorOptions() {
            const data = this.getCurrentFilterData()
            console.log('Dispatching request:', data)
            this.isLoading = true
            productAPI.getFilteredProducts(data)
                .then(response => {
                    const meta = response.data.meta
                    const productColorOptions = response.data.data
                    this.pagination = meta.pagination
                    this.productColorOptions = productColorOptions
                    this.isLoading = false
                    this.error.hasError = false
                })
                .catch(e => {
                    // Looking to see if this error is a cancellation error.
                    // If it is, to us that means there's not actually an "error", even though axios throws one.
                    // We want the loading state to continue if there's been a cancellation/change in filters, that's why we're doing this.
                    if (!(e instanceof Cancel)) {
                        this.isLoading = false
                        this.error.hasError = true
                        this.error.message = e.response ? e.response.data.error : 'Unknown error.'
                    }
                })
                .then(() => {
                })
        },
        /**
         * Add a sample to the cart.
         */
        addSampleToCart(sampleId, colorOptionIndex) {
            const currentCartQuantity = getCartQuantity()

            if (currentCartQuantity < 3 && !samplesInCart.includes(sampleId)) {
                this.isAddingSamples = true
                // Make some params
                let params = new URLSearchParams({
                    action: 'commerce/cart/update-cart',
                    [window.pageInfo.csrfTokenName]: window.pageInfo.csrfToken
                })
                // Actually add the sample item to the params
                params.append('purchasables[][id]', sampleId)
                // Now post the params
                productAPI.postSamples(params.toString())
                    .then(response => {
                        this.isAddingSamples = false
                        if (!samplesInCart.includes(sampleId)) {
                            this.samplesInCart.push(sampleId)
                            this.productColorOptions[colorOptionIndex].isInCart = true

                            const newCart = response.data.cart
                            updateCartQuantity(newCart.totalQty)
                        }
                    })
                    .catch(e => {
                        this.isAddingSamples = false
                        this.error.hasError = true
                        this.error.message = e.response ? e.response.data.error : 'Unknown error.'
                    })
            }
        },
        /**
         * Handles pagination and loading more color options/collections.
         */
        handleLoadMore() {
            // If there is even a next link
            if (this.pagination.links.next) {
                const data = this.getCurrentFilterData()
                this.isLoadingMore = true
                productAPI.getNextProducts(this.pagination.links.next, data)
                    .then(response => {
                        const meta = response.data.meta
                        const newProductColorOptions = response.data.data
                        const productColorOptions = [
                            ...this.productColorOptions,
                            ...newProductColorOptions
                        ]
                        this.pagination = meta.pagination
                        this.productColorOptions = productColorOptions
                        this.isLoadingMore = false
                    })
                    .catch(e => {
                        // Looking to see if this error is a cancellation error.
                        // If it is, to us that means there's not actually an "error", even though axios throws one.
                        // We want the loading state to continue if there's been a cancellation/change in filters, that's why we're doing this.
                        if (!(e instanceof Cancel)) {
                            this.isLoading = false
                            this.error.hasError = true
                            this.error.message = e.response ? e.response.data.error : 'Unknown error.'
                        }
                    })
            }
        },
        togglePopout() {
            this.popoutOpen = !this.popoutOpen
        },
        toggleProductView() {
            this.blockViewActive = !this.blockViewActive
        },
        handleChangeMetaFilters(filters) {
            this.metaFilters = filters
            this.debouncedgetColorOptions()
        }
    },
    watch: {
        metaFilters: {
            handler: () => {
            },
            deep: true
        }
    },
    computed: {
        productCollections() {
            const newCollectionsArray = []
            /**
             * This loops through the color options and looks at the collection for each option,
             * and checks if that collection is in the newCollectionsArray array already.
             * If the collection has already been added to newCollectionsArray, the color option gets
             * added to that collection inside newCollectionsArray.
             * Otherwise, that collection is added to newCollectionsArray, with the color option getting
             * added to that collection's colorOptions array property.
             */
            this.productColorOptions.forEach(colorOption => {
                const collection = colorOption.collection
                const collectionId = findIndex(newCollectionsArray, { 'id': collection ? collection.id : '' })
                if (collectionId > -1) {
                    // newCollectionsArray has item
                    newCollectionsArray[collectionId].colorOptions.push(colorOption)
                } else if (collection) {
                    // newCollectionsArray does not have item
                    newCollectionsArray.push({
                        ...collection,
                        colorOptions: [ colorOption ]
                    })
                }
            })

            return newCollectionsArray
        }
    }
}
</script>
