var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isLoading && _vm.productCollections.length && !_vm.error.hasError
      ? _c(
          "ul",
          {
            staticClass: "product-list clearfix",
            attrs: { "data-equalizer": "" },
          },
          _vm._l(_vm.productCollections, function (collection) {
            return _c("ProductItem", {
              key: collection.id,
              attrs: { collection: collection },
            })
          }),
          1
        )
      : _vm.error.hasError && !_vm.isLoading
      ? _c("div", { staticClass: "no-results error-state" }, [
          _vm._v(
            "\n        There was an error, please try again or contact us if this keeps happening.\n        "
          ),
          _c("span", { staticClass: "error-message" }, [
            _vm._v(_vm._s(_vm.error.message)),
          ]),
        ])
      : !_vm.isLoading && !_vm.productCollections.length
      ? _c("div", { staticClass: "no-results" }, [
          _vm._v("\n        No results.\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isLoading
      ? _c("div", { staticClass: "small-12 text-center" }, [
          _c(
            "a",
            {
              class: { loading: _vm.isLoading },
              style: {
                display: _vm.isLoading ? "block" : "none",
                opacity: _vm.isLoading ? 1 : 0,
              },
              attrs: { id: "blog-load-more" },
            },
            [
              _vm._v("\n            Loading products...\n            "),
              _c("span", { staticClass: "more-blog-loading-icon" }),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }